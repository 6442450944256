/* eslint-disable indent */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid'
import { observer } from 'mobx-react-lite'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import dayjs from 'dayjs'
import { collection, onSnapshot, query } from 'firebase/firestore'
import { v4 as uuid4 } from 'uuid'

// Components
import { AddUserIcon } from '../../components/AddUserIcon'
import { Button } from '../../components/Button'
import { CheckBox } from '../../components/CheckBox'
import { CircleCheckIconSolid } from '../../components/CircleCheckIconSolid'
import { DataTable } from '../../components/DataTable'
import { EditAttendeeModal } from '../../components/EditAttendeeModal'
import { PrinterIcon } from '../../components/PrinterIcon'
import { QRIcon } from '../../components/QRIcon'
import { TextInput } from '../../components/TextInput'
import { Tooltip } from '../../components/Tooltip'
import QuickBadgeModal from './QuickBadgeModal'

// Store
import { KioskStoreContext } from '../../stores/KioskStore'

// Services & Utils
import { convertIncomingData } from '../../services/transforms'
import { instance } from '../../services/firestore/instance'
import { addBadgePrintLog, updateAttendee } from '../../services/firestore/firestore.service'
import { GDPR_COUNTRIES } from '../../utils/constants'
import colors from '../../utils/colors'

const CATEGORY_COLORS = [
  { background: colors.purple.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.teal.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.blue.DEFAULT, label: colors.white.DEFAULT },
  { background: colors.purple[900], label: colors.white.DEFAULT },
  { background: colors.teal[900], label: colors.white.DEFAULT },
  { background: colors.blue[900], label: colors.white.DEFAULT },
  { background: colors.purple.light, label: colors.gray.dark },
  { background: colors.teal.light, label: colors.gray.dark },
  { background: colors.blue.light, label: colors.gray.dark },
  { background: colors.purple.dark, label: colors.gray.dark },
  { background: colors.teal.dark, label: colors.gray.dark },
  { background: colors.blue.dark, label: colors.gray.dark },
]

const getCategoryFromId = (id, categories) =>
  _.find(_.keys(categories), (k) => categories[k].category_id === id)

const Staff = observer(
  ({
    configuration,
    handleBulkPrint,
    registrationSyncData,
    registrationSyncEnabled,
    setAttendeeToCheckIn,
  }) => {
    // Context
    const { eventId, location, name } = useContext(KioskStoreContext)

    // State
    const [attendees, setAttendees] = useState([])
    const [selectedAttendees, setSelectedAttendees] = useState([])
    const [activeTab, setActiveTab] = useState('attendee')
    const [attendeeLastUpdated, setAttendeeLastUpdated] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [attendeeToEdit, setAttendeeToEdit] = useState(null)
    const [quickBadges, setQuickBadges] = useState([])
    const [selectedQuickBadges, setSelectedQuickBadges] = useState([])
    const [quickBadgeLastUpdated, setQuickBadgeLastUpdated] = useState('')
    const [showQuickBadgeModal, setShowQuickBadgeModal] = useState(false)
    const [quickBadgeDuplicate, setQuickBadgeDuplicate] = useState(null)

    // Ref
    const searchInputRef = useRef()

    /**
     * Filters the results based on the search value.
     * @param {string} dataType
     * @param {string} search
     * @param {object} snapshot
     */
    const getFilteredResults = (dataType, search, snapshot) => {
      const filteredResults = _.filter(
        snapshot.docs.map((d) => convertIncomingData(d.data())),
        (a) => {
          const searchValue = search.toLowerCase()
          const category =
            _.find(
              _.keys(registrationSyncData?.attendeeCategories),
              (k) =>
                registrationSyncData?.attendeeCategories[k].category_id ===
                a.customData?.categoryid,
            ) || ''

          const searchParts = searchValue.split(' ')

          return searchParts.every(
            (part) =>
              a.firstName?.toLowerCase().includes(part) ||
              a.lastName?.toLowerCase().includes(part) ||
              a.email?.toLowerCase().includes(part) ||
              a.companyName?.toLowerCase().includes(part) ||
              a.category?.toLowerCase().includes(part) ||
              category.toLowerCase().includes(part),
          )
        },
      )

      if (dataType === 'attendee') setAttendees(filteredResults)
      else if (dataType === 'quickBadges') setQuickBadges(filteredResults)
    }

    const filterAttendeeResults = useCallback(_.debounce(getFilteredResults, 150), [searchTerm])
    const filterQuickBadgeResults = useCallback(_.debounce(getFilteredResults, 150), [searchTerm])

    /**
     * Gets attendees and quick badges from firestore and adds a listener for real-time updates.
     */
    useEffect(() => {
      const attendeeQuery = query(collection(instance, `events/${eventId}/kiosk-attendees`))
      const quickBadgeQuery = query(collection(instance, `events/${eventId}/quick-badges`))

      const unsubscribeAttendeeSnapshot = onSnapshot(attendeeQuery, (snapshot) => {
        filterAttendeeResults('attendee', searchTerm, snapshot)
        setAttendeeLastUpdated(new Date())
      })

      const unsubscribeQuickBadgeSnapshot = onSnapshot(quickBadgeQuery, (snapshot) => {
        filterQuickBadgeResults('quickBadges', searchTerm, snapshot)
        setQuickBadgeLastUpdated(new Date())
      })

      return () => {
        if (unsubscribeAttendeeSnapshot) unsubscribeAttendeeSnapshot()
        if (unsubscribeQuickBadgeSnapshot) unsubscribeQuickBadgeSnapshot()
      }
    }, [searchTerm])

    const configureBulkPrintLabel = () => {
      if (activeTab === 'attendee') {
        return `Bulk Print Badge ${
          selectedAttendees.length > 1 ? `(${selectedAttendees.length})` : ''
        }`
      }

      return `Bulk Print Badge ${
        selectedQuickBadges.length > 1 ? `(${selectedQuickBadges.length})` : ''
      }`
    }

    /**
     * Configures the category tag to display for the attendee using the same colors as the KPIs.
     * @param {string} category
     */
    const configureCategoryTag = (category) => {
      const index = _.findIndex(
        _.sortBy(_.keys(registrationSyncData.attendeeCategories)),
        (k) => k === category,
      )

      let displayColors
      if (index === -1) {
        displayColors = { background: colors.gray.light, label: colors.gray.dark }
      } else
        displayColors = CATEGORY_COLORS[index] || {
          background: colors.gray.light,
          label: colors.gray.dark,
        }

      return (
        <span
          className="rounded-full px-2.5 py-1 text-xs font-medium"
          style={{ backgroundColor: displayColors.background, color: displayColors.label }}
        >
          {_.capitalize(category) || 'No Category'}
        </span>
      )
    }

    const renderLastUpdated = () => {
      if (activeTab === 'attendee') {
        return (
          <div className="mr-2 mt-2 text-right text-xs text-gray-600">
            Last Update: {dayjs(attendeeLastUpdated).format('M/D/YY h:mm A')}
          </div>
        )
      }

      return (
        <div className="mr-2 mt-2 text-right text-xs text-gray-600">
          Last Update: {dayjs(quickBadgeLastUpdated).format('M/D/YY h:mm A')}
        </div>
      )
    }

    return (
      <div className="flex h-full max-h-[600px] flex-col">
        <div className="mb-2 flex justify-between">
          <div className="flex items-center gap-4">
            <button
              className={mergeClassNames(
                'text-md border-b-2 pb-2 font-medium text-purple',
                activeTab === 'attendee'
                  ? 'border-purple'
                  : 'border-b-transparent text-gray-600 hover:border-b-purple hover:text-purple',
              )}
              type="button"
              onClick={() => setActiveTab('attendee')}
            >
              Attendee
            </button>

            <button
              className={mergeClassNames(
                'text-md border-b-2 pb-2 font-medium text-purple',
                activeTab === 'quickBadges'
                  ? 'border-purple'
                  : 'border-b-transparent text-gray-600 hover:border-b-purple hover:text-purple',
              )}
              type="button"
              onClick={() => setActiveTab('quickBadges')}
            >
              Quick Badges
            </button>
          </div>

          <div className="flex items-center gap-4 self-start">
            <Button
              background="bg-teal"
              icon={<AddUserIcon className="h-5 fill-black sm:h-6" />}
              label="Add Quick Badge"
              onClick={() => setShowQuickBadgeModal(true)}
            />

            <Button
              disabled={
                activeTab === 'attendee'
                  ? selectedAttendees.length < 2
                  : selectedQuickBadges.length < 2
              }
              icon={<PrinterIcon className="h-5 fill-white" />}
              label={configureBulkPrintLabel()}
              onClick={() => {
                let filteredAttendees = []

                if (activeTab === 'attendee') {
                  // Filter out attendees that can't be checked in with a bulk print
                  filteredAttendees = _.filter(selectedAttendees, (a) => {
                    if (
                      a.customData?.registrationstatus !== 'Confirmed' &&
                      a.customData?.registrationstatus !== 'Attended'
                    )
                      return false
                    if (a.customData?.balance_due > 0) return false
                    // Attendees needing GDPR consent don't need to answer it again after being checked in
                    if (!a.isCheckedIn && GDPR_COUNTRIES.includes(a.customData?.country))
                      return false
                    return true
                  })

                  _.forEach(filteredAttendees, (a) => {
                    // Only update check-in status if the attendee hasn't checked-in already
                    if (!a.isCheckedIn && !a.quickBadge) {
                      // Mark attendee as checked in
                      updateAttendee(eventId, {
                        ...a,
                        isCheckedIn: true,
                        checkInLocation: location.id,
                        checkInLocationName: name,
                        checkedInAt: new Date(),
                      })
                    }

                    // Log Badge Print
                    const printLog = {
                      printedAt: new Date(),
                      id: `${uuid4()}`,
                    }
                    if (a.quickBadge) {
                      printLog.quickBadge = a.id
                    } else {
                      printLog.attendee = a.id
                    }

                    addBadgePrintLog(eventId, printLog)
                  })
                } else {
                  filteredAttendees = selectedQuickBadges
                }

                handleBulkPrint(filteredAttendees)

                // Clear selected attendees
                if (activeTab === 'attendee') setSelectedAttendees([])
                else setSelectedQuickBadges([])
              }}
            />
          </div>
        </div>

        <TextInput
          ref={searchInputRef}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-[420px] rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
          icon={<MagnifyingGlassIcon className="ml-2 h-5 text-gray-dark" aria-hidden="true" />}
          placeholder="Search by First Name, Last Name, Email, Company, or Category"
          endIcon={
            searchTerm ? (
              <button
                type="button"
                onClick={() => {
                  setSearchTerm('')
                  searchInputRef.current.value = ''
                }}
              >
                <XMarkIcon className="mr-2 h-5 text-gray-dark" aria-hidden="true" />
              </button>
            ) : null
          }
        />

        <div className="mt-4 flex min-h-[0px] flex-col">
          <DataTable
            className="w-full"
            columns={[
              {
                id: 'select',
                width: '48px',
                name: (
                  <div className="flex flex-row">
                    <CheckBox
                      className="ml-2"
                      onChange={() => {
                        // If viewing attendees, select all attendees
                        if (
                          activeTab === 'attendee' &&
                          (selectedAttendees?.length === 0 ||
                            selectedAttendees?.length < attendees?.length)
                        ) {
                          setSelectedAttendees(attendees)
                        }
                        // Otherwise, clear all selected attendees
                        else if (activeTab === 'attendee') {
                          setSelectedAttendees([])
                        }
                        // If viewing quick badges, select all quick badges
                        else if (
                          selectedQuickBadges?.length === 0 ||
                          selectedQuickBadges?.length < quickBadges?.length
                        ) {
                          setSelectedQuickBadges(quickBadges)
                        }
                        // Otherwise, clear all selected quick badges
                        else {
                          setSelectedQuickBadges([])
                        }
                      }}
                      value={selectedAttendees?.length === attendees?.length}
                    />
                  </div>
                ),
                selector: (row) => row.id,
                cell: (row) => {
                  let selected = null
                  if (activeTab === 'attendee') {
                    selected = _.find(selectedAttendees, (a) => a.id === row.id)
                  } else {
                    selected = _.find(selectedQuickBadges, (a) => a.id === row.id)
                  }

                  return (
                    <div className="">
                      <CheckBox
                        className="ml-2"
                        onChange={() => {
                          if (activeTab === 'attendee' && selected) {
                            setSelectedAttendees(selectedAttendees.filter((a) => a.id !== row.id))
                          } else if (activeTab === 'attendee') {
                            setSelectedAttendees([...selectedAttendees, row])
                          } else if (selected) {
                            setSelectedQuickBadges(
                              selectedQuickBadges.filter((a) => a.id !== row.id),
                            )
                          } else {
                            setSelectedQuickBadges([...selectedQuickBadges, row])
                          }
                        }}
                        value={selected}
                      />
                    </div>
                  )
                },
                sortable: false,
              },
              {
                id: 'print',
                cell: (row) => (
                  <Button
                    background={
                      row.isCheckedIn || activeTab === 'quickBadges' ? 'bg-teal' : 'bg-purple'
                    }
                    disabled={
                      row.customData &&
                      row.customData?.registrationstatus !== 'Confirmed' &&
                      row.customData?.registrationstatus !== 'Attended' &&
                      activeTab !== 'quickBadges'
                    }
                    label={
                      row.isCheckedIn || activeTab === 'quickBadges' ? 'Re-Print' : 'Print Badge'
                    }
                    onClick={() => setAttendeeToCheckIn(row)}
                  />
                ),
                width: '150px',
                center: true,
              },
              {
                id: 'name',
                name: 'Name',
                selector: (row) => row.firstName,
                cell: (row) => {
                  let status = <CircleCheckIconSolid className="h-4 fill-purple" />
                  let tooltip = 'Confirmed'
                  let background = 'bg-purple'
                  if (
                    row.customData &&
                    row.customData?.registrationstatus !== 'Confirmed' &&
                    row.customData?.registrationstatus !== 'Attended'
                  ) {
                    status = <XCircleIcon className="h-4 fill-red" />
                    tooltip = 'Registration Error'
                    background = 'bg-red'
                  } else if (row.customData && row.customData?.balance_due > 0) {
                    status = <ExclamationTriangleIcon className="h-4 fill-blue" />
                    tooltip = 'Balance Due'
                    background = 'bg-blue'
                  } else if (
                    !row.isCheckedIn &&
                    row.customData &&
                    GDPR_COUNTRIES.includes(row.customData?.country)
                  ) {
                    status = <ExclamationTriangleIcon className="h-4 fill-blue" />
                    tooltip = 'GDPR'
                    background = 'bg-blue'
                  }

                  return (
                    <div className="flex flex-col gap-1">
                      <div className="flex flex-row gap-1">
                        {activeTab === 'attendee' && (
                          <Tooltip
                            content={
                              <span
                                className={mergeClassNames(
                                  'rounded-lg px-2 py-1 text-white shadow-lg',
                                  background,
                                )}
                              >
                                {tooltip}
                              </span>
                            }
                            placement="top"
                          >
                            {status}
                          </Tooltip>
                        )}
                        <span className="font-medium text-gray-dark">
                          {row.firstName} {row.lastName}
                        </span>
                      </div>
                      {activeTab === 'attendee' && <span className="truncate">{row.email}</span>}
                    </div>
                  )
                },
                sortable: true,
                grow: 1,
              },
              {
                id: 'companyAndTitle',
                name: 'Company/Title',
                selector: (row) => row.companyName.toLowerCase(),
                cell: (row) => (
                  <div className="flex flex-col gap-1 text-gray-dark">
                    <span>{row.companyName}</span>
                    <span className="truncate text-gray-600">{row.title}</span>
                  </div>
                ),
                sortable: true,
                grow: 1,
              },
              {
                id: 'category',
                name: 'Category',
                selector: 'category',
                sortFunction: (rowA, rowB) => {
                  // If there's no category id, sort to the bottom
                  if (rowA.customData?.categoryid === null) {
                    return 1
                  }

                  if (rowB.customData?.categoryid === null) {
                    return -1
                  }

                  const a = getCategoryFromId(
                    rowA.customData?.categoryid,
                    registrationSyncData?.attendeeCategories,
                  )?.toLowerCase()

                  const b = getCategoryFromId(
                    rowB.customData?.categoryid,
                    registrationSyncData?.attendeeCategories,
                  )?.toLowerCase()

                  // If there's no category, sort to the bottom
                  if (!a) {
                    return 1
                  }

                  if (!b) {
                    return -1
                  }

                  if (a > b) {
                    return 1
                  }

                  if (b > a) {
                    return -1
                  }

                  return 0
                },
                cell: (row) => {
                  if (!registrationSyncData?.attendeeCategories || !row.customData?.categoryid)
                    return null

                  const category = getCategoryFromId(
                    row.customData?.categoryid,
                    registrationSyncData?.attendeeCategories,
                  )

                  return configureCategoryTag(category)
                },
                sortable: true,
                width: '150px',
                omit: !registrationSyncEnabled,
              },
              {
                id: 'edit',
                cell: (row) => (
                  <button
                    className="font-bold text-blue"
                    type="button"
                    onClick={() => {
                      if (activeTab === 'attendee') setAttendeeToEdit(row)
                      else setQuickBadgeDuplicate(row)
                    }}
                  >
                    {activeTab === 'attendee' ? 'Edit' : 'Duplicate'}
                  </button>
                ),
                width: '100px',
                center: true,
              },
            ]}
            data={activeTab === 'attendee' ? attendees : quickBadges}
            defualtSortAsc
            defaultSortField="name"
          />
        </div>

        <div className="mb-2 flex justify-end">{renderLastUpdated()}</div>

        <div className="absolute bottom-2 mt-8 flex items-center gap-2">
          <QRIcon className="h-4 fill-black" />
          {name}
        </div>

        {(showQuickBadgeModal || quickBadgeDuplicate) && (
          <QuickBadgeModal
            baseQuickBadge={quickBadgeDuplicate}
            handleCheckIn={setAttendeeToCheckIn}
            eventId={eventId}
            registrationSyncData={registrationSyncData}
            closeModal={() => {
              setQuickBadgeDuplicate(null)
              setShowQuickBadgeModal(false)
            }}
          />
        )}

        {attendeeToEdit && (
          <EditAttendeeModal
            attendeeToEdit={attendeeToEdit}
            closeModal={() => setAttendeeToEdit(null)}
            deviceLocations={configuration.kioskDeviceLocations}
            handleCheckIn={(attendee) => {
              setAttendeeToCheckIn(attendee)
              setAttendeeToEdit(null)
            }}
            externalPopupField={configuration.externalPopupField}
            registrationSyncData={registrationSyncData}
            registrationSyncEnabled={registrationSyncEnabled}
          />
        )}
      </div>
    )
  },
)

Staff.propTypes = {
  handleBulkPrint: PropTypes.func.isRequired,
  registrationSyncData: PropTypes.object.isRequired,
  registrationSyncEnabled: PropTypes.bool.isRequired,
  setAttendeeToCheckIn: PropTypes.func.isRequired,
}

export { Staff }
