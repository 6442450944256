import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import { ArrowSmallDownIcon } from '@heroicons/react/20/solid'

// Styles
import baseColors from '../../utils/colors'

export const defaultStyles = {
  responsiveWrapper: {
    style: {
      border: `1px solid ${baseColors.gray.light}`,
      borderRadius: '8px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    },
  },
  head: {
    style: {
      height: '40px',
    },
  },
  headRow: {
    style: {
      backgroundColor: baseColors.gray[100],
      textTransform: 'uppercase',
      minHeight: '40px',
      borderBottomColor: baseColors.gray.light,
    },
  },
  rows: {
    style: {
      fontSize: '14px',
      lineHeight: '20px',
      color: baseColors.gray[600],
      minHeight: '52px',
      '&:not(:last-of-type)': {
        borderBottomColor: baseColors.gray.light,
      },
    },
  },
  pagination: {
    style: {
      color: baseColors.gray[900],
      fontSize: '12px',
      minHeight: '30px',
      backgroundColor: 'transparent',
      border: 'none',
      fontWeight: '600',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '32px',
      width: '32px',
      padding: '4px',
      margin: 'px',
      cursor: 'pointer',
      transition: '0.4s',
      outline: 'none',
      color: 'black',
      fill: 'black',
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: 'black',
        opacity: 0.25,
        fill: 'black',
      },
      '&:hover:not(:disabled)': {
        backgroundColor: baseColors.purple.DEFAULT,
        fill: 'white',
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: baseColors.purple.DEFAULT,
        fill: 'white',
      },
    },
  },
  headCells: {
    style: {
      '&:hover *': {
        opacity: '1 !important',
      },
      'li span': {
        textTransform: 'none',
      },
    },
  },
}

/**
 *
 * DataTable
 *
 * - Displays a table of data
 */
const CustomDataTable = ({
  columns,
  customStyles,
  data,
  onChangePage,
  pagination,
  paginationServer,
  progressPending,
  responsive,
  sortingEnabled,
  title,
  ...rest
}) => {
  // State
  const [tableDim, setTableDim] = useState(0)
  const [loading, setLoading] = useState(false)

  const tableElement = document.querySelector('.rdt_Table')
  const paginationElement = document.querySelector('.rdt_Pagination')

  useEffect(() => {
    if (tableElement && paginationElement) {
      const tableDimensions = tableElement.getBoundingClientRect()
      const paginationDimensions = paginationElement.getBoundingClientRect()

      setTableDim({
        height: `${paginationDimensions.top - tableDimensions.top}px`,
        width: `${tableDimensions.width}px`,
      })
    }
  }, [paginationElement, tableElement])

  useEffect(() => {
    if (progressPending && data && !loading) {
      setLoading(true)
    } else if (!progressPending && data && loading) {
      setLoading(false)
    }
  }, [progressPending, data])

  return (
    <div className="relative flex h-full flex-col overflow-hidden">
      <DataTable
        columns={columns}
        customStyles={customStyles || defaultStyles}
        data={data}
        disabled={progressPending}
        fixedHeader
        fixedHeaderScrollHeight={200}
        onChangePage={onChangePage}
        pagination={pagination}
        paginationServer={paginationServer}
        progressPending={!data && progressPending}
        title={title}
        responsive
        sortIcon={<ArrowSmallDownIcon />}
        {...rest}
      />

      {sortingEnabled && tableDim && loading && data ? (
        <div
          // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
          className="absolute z-20 flex cursor-not-allowed items-center justify-center rounded-lg bg-gray-light bg-opacity-40 align-middle"
          style={{ height: tableDim.height, width: tableDim.width }}
        >
          <p className="text-xl font-semibold opacity-100">Loading...</p>
        </div>
      ) : null}
    </div>
  )
}

CustomDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  customStyles: PropTypes.object,
  data: PropTypes.array.isRequired,
  onChangePage: PropTypes.func,
  pagination: PropTypes.bool,
  paginationServer: PropTypes.bool,
  progressPending: PropTypes.bool.isRequired,
  responsive: PropTypes.bool,
  sortingEnabled: PropTypes.bool,
  title: PropTypes.string,
}

CustomDataTable.defaultProps = {
  customStyles: null,
  onChangePage: null,
  pagination: false,
  paginationServer: false,
  responsive: false,
  sortingEnabled: true,
  title: null,
}

export default CustomDataTable
