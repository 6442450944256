/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { twMerge as mergeClassNames } from 'tailwind-merge'
import { renderToStaticMarkup } from 'react-dom/server'
import { IoSendSharp } from 'react-icons/io5'

// Components
import { DoodleSticks } from '../DoodleSticks'

// Images
import CheckCircle from '../../assets/images/check_circle.svg'
import Tasklist from '../../assets/images/tasklist.svg'

const EMAIL_TASKS = [
  'send_event_reminder_email',
  'send_kbyg_for_exhibitors',
  'send_attendee_scan_and_go_email',
]

/**
 * TasklistTile
 *
 * - Displays task list
 */
const TasklistTile = ({ sendToEmails, tasklist }) => {
  const doodle = encodeURIComponent(
    renderToStaticMarkup(<DoodleSticks fill="#000000" opacity={0.04} />),
  )

  return (
    <div className="h-max-[400px] flex h-full w-full flex-col rounded-lg bg-white p-3 shadow-lg">
      <div
        className="relative flex h-32 w-full place-content-center rounded-[10px] bg-teal"
        style={{
          backgroundImage: `url('data:image/svg+xml;utf8, ${doodle}')`,
          backgroundSize: 'cover',
        }}
      >
        <img className="absolute top-[-10px] h-[8.5rem]" src={Tasklist} alt="Tasklist" />
      </div>

      <span className="text-md mt-3 font-bold">Tasklist</span>

      <div className="mt-6 flex flex-col space-y-2">
        {_.map(
          _.sortBy(tasklist, (t) => t.status),
          (t) => (
            <div
              className={mergeClassNames(
                'flex w-full flex-row place-items-center justify-between space-x-2 rounded-full bg-[#F8F8F8] px-3 py-2',
                !t.isComplete && 'border-[1px] border-purple',
              )}
              key={`task:${t.title}`}
            >
              <div className="flex w-full flex-row space-x-2">
                {!t.isComplete ? (
                  <div className="h-4 w-4 rounded-full bg-gray-400" />
                ) : (
                  <img alt="Complete" className="h-4" src={CheckCircle} />
                )}

                <span className="text-sm font-bold">{t.title}</span>
              </div>

              {EMAIL_TASKS.includes(t.identifier) && !t.isComplete && (
                <button className="" onClick={sendToEmails} type="button">
                  <IoSendSharp className="fill-purple hover:fill-purple-800" size={18} />
                </button>
              )}
            </div>
          ),
        )}
      </div>
    </div>
  )
}

TasklistTile.propTypes = {
  sendToEmails: PropTypes.func.isRequired,
  tasklist: PropTypes.array.isRequired,
}

export default TasklistTile
