import { initializeApp } from 'firebase/app'
import {
  CACHE_SIZE_UNLIMITED,
  getFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
} from 'firebase/firestore'

const FIREBASE_CONFIG = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
}

// If Google Analytics is enabled, add the measurement ID to the config
if (import.meta.env.VITE_GOOGLE_ANALYTICS_ENABLED === 'true') {
  FIREBASE_CONFIG.measurementId = import.meta.env.VITE_GOOGLE_ANALYTICS_ID
}

// Initialize Firebase and Firestore
export const firebaseApp = initializeApp(FIREBASE_CONFIG, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
})

export const instance = getFirestore(firebaseApp)
