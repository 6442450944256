import React, { useContext, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import cn from 'classnames'
import { observer } from 'mobx-react'

// Components
import { Button } from '../../components/Button'
import { DatePicker } from '../../components/DatePicker'
import { EditIcon } from '../../components/EditIcon'
import { EventHeader } from '../../components/EventHeader'
import { StateContainer } from '../../components/StateContainer'
import { TextInput } from '../../components/TextInput'

// Images
import Arrow from '../../assets/images/arrow-right.svg'
import Check from '../../assets/images/check.svg'
import Dollar from '../../assets/images/dollar.svg'
import Doodlesticks from '../../assets/images/doodle_sticks.png'

// Service
import { updateEvent } from '../../services/events.service'

// Stores
import { NavigationStoreContext } from '../../stores/NavigationStore'

// Utils
import { toast } from '../../utils/helpers'

const PRICING_TYPES = [
  { key: 'earlyBird', label: 'Early Bird Pricing' },
  { key: 'standard', label: 'Standard Pricing' },
  { key: 'onsite', label: 'Onsite Pricing' },
]

/**
 *
 * OrganizerEventLicenses
 *
 */
const OrganizerEventLicenses = observer(() => {
  // Context
  const { event, setEvent } = useContext(NavigationStoreContext)

  // State
  const [pricing, setPricing] = useState('earlyBird')
  const [showStartStandard, setShowStartStandard] = useState(false)
  const [showEndStandard, setShowEndStandard] = useState(false)
  const [showStartEarlyBird, setShowStartEarlyBird] = useState(false)
  const [showEndEarlyBird, setShowEndEarlyBird] = useState(false)
  const [showStartOnsite, setShowStartOnsite] = useState(false)
  const [showEndOnsite, setShowEndOnsite] = useState(false)
  const [loadingEvent, setLoadingEvent] = useState(false)

  const handleErrors = (m) => toast(m, 'error')
  const handleSuccess = (m) => toast(m, 'success')

  const {
    control: earlyBirdControl,
    formState: { errors: earlyBirdErrors },
    handleSubmit: earlyBirdHandleSubmit,
    getValues: earlyBirdGetValues,
    register: earlyBirdRegister,
    reset: earlyBirdReset,
    setValue: earlyBirdSetValue,
  } = useForm({
    defaultValues: {
      earlyBirdStartsAt: event.earlyBirdStartsAt,
      earlyBirdEndsAt: event.earlyBirdEndsAt,
      earlyBirdFirstPrice: event.earlyBirdFirstPrice,
      earlyBirdAdditionalPrice: event.earlyBirdAdditionalPrice,
      earlyBirdRentalPrice: event.earlyBirdRentalPrice,
    },
  })

  const {
    control: standardControl,
    formState: { errors: standardErrors },
    handleSubmit: standardHandleSubmit,
    getValues: standardGetValues,
    register: standardRegister,
    reset: standardReset,
    setValue: standardSetValue,
  } = useForm({
    defaultValues: {
      standardStartsAt: event.standardStartsAt,
      standardEndsAt: event.standardEndsAt,
      standardFirstPrice: event.standardFirstPrice,
      standardAdditionalPrice: event.standardAdditionalPrice,
      standardRentalPrice: event.standardRentalPrice,
    },
  })

  const {
    control: onsiteControl,
    formState: { errors: onsiteErrors },
    handleSubmit: onsiteHandleSubmit,
    getValues: onsiteGetValues,
    register: onsiteRegister,
    reset: onsiteReset,
    setValue: onsiteSetValue,
  } = useForm({
    defaultValues: {
      onsiteStartsAt: event.onsiteStartsAt || event.startsAt,
      onsiteEndsAt: event.onsiteEndsAt || event.endsAt,
      onsiteFirstPrice: event.onsiteFirstPrice,
      onsiteAdditionalPrice: event.onsiteAdditionalPrice,
      onsiteRentalPrice: event.onsiteRentalPrice,
    },
  })

  const reset = (data) => {
    if (pricing === 'earlyBird') {
      earlyBirdReset(
        data || {
          earlyBirdStartsAt: event.earlyBirdStartsAt,
          earlyBirdEndsAt: event.earlyBirdEndsAt,
          earlyBirdFirstPrice: event.earlyBirdFirstPrice,
          earlyBirdAdditionalPrice: event.earlyBirdAdditionalPrice,
          earlyBirdRentalPrice: event.earlyBirdRentalPrice,
        },
      )
    } else if (pricing === 'standard') {
      standardReset(
        data || {
          standardStartsAt: event.standardStartsAt,
          standardEndsAt: event.standardEndsAt,
          standardFirstPrice: event.standardFirstPrice,
          standardAdditionalPrice: event.standardAdditionalPrice,
          standardRentalPrice: event.standardRentalPrice,
        },
      )
    } else if (pricing === 'onsite') {
      onsiteReset(
        data || {
          onsiteStartsAt: event.onsiteStartsAt || event.startsAt,
          onsiteEndsAt: event.onsiteEndsAt || event.endsAt,
          onsiteFirstPrice: event.onsiteFirstPrice,
          onsiteAdditionalPrice: event.onsiteAdditionalPrice,
          onsiteRentalPrice: event.onsiteRentalPrice,
        },
      )
    }
  }

  useEffect(() => {
    if (event) {
      reset()
    }
  }, [])

  const submitEvent = async (data) => {
    const updatedEvent = {
      id: event.id,
      [`${pricing}StartsAt`]:
        data[`${pricing}StartsAt`] && dayjs(data[`${pricing}StartsAt`]).format('YYYY-MM-DD'),
      [`${pricing}EndsAt`]:
        data[`${pricing}EndsAt`] && dayjs(data[`${pricing}EndsAt`]).format('YYYY-MM-DD'),
      [`${pricing}RentalPrice`]: data[`${pricing}RentalPrice`],
      [`${pricing}FirstPrice`]: data[`${pricing}FirstPrice`],
      [`${pricing}AdditionalPrice`]: data[`${pricing}AdditionalPrice`],
    }

    const response = await updateEvent(updatedEvent, handleErrors, setLoadingEvent, async (m) => {
      handleSuccess(m)
    })

    if (response) {
      setEvent(response)
      reset({
        [`${pricing}StartsAt`]: response[`${pricing}StartsAt`],
        [`${pricing}EndsAt`]: response[`${pricing}EndsAt`],
        [`${pricing}RentalPrice`]: response[`${pricing}RentalPrice`],
        [`${pricing}FirstPrice`]: response[`${pricing}FirstPrice`],
        [`${pricing}AdditionalPrice`]: response[`${pricing}AdditionalPrice`],
      })
    }
  }

  const renderPricingForm = () => {
    let control = earlyBirdControl
    let errors = earlyBirdErrors
    let getValues = earlyBirdGetValues
    let register = earlyBirdRegister
    let setValue = earlyBirdSetValue

    if (pricing === 'standard') {
      control = standardControl
      errors = standardErrors
      getValues = standardGetValues
      register = standardRegister
      setValue = standardSetValue
    } else if (pricing === 'onsite') {
      control = onsiteControl
      errors = onsiteErrors
      getValues = onsiteGetValues
      register = onsiteRegister
      setValue = onsiteSetValue
    }

    return (
      <div key={pricing} style={{ display: 'block' }}>
        <div className="mb-4 flex w-full flex-col gap-2 sm:flex-row">
          <Controller
            name={`${pricing}StartsAt`}
            control={control}
            render={({ field: { onChange, value } }) => {
              let show = showStartStandard
              let setShow = setShowStartStandard
              if (pricing === 'earlyBird') {
                show = showStartEarlyBird
                setShow = setShowStartEarlyBird
              } else if (pricing === 'onsite') {
                show = showStartOnsite
                setShow = setShowStartOnsite
              }

              return (
                <DatePicker
                  label="Active from"
                  disabled={loadingEvent}
                  name={`${pricing}StartsAt`}
                  onChange={onChange}
                  placeholder="Start Date"
                  show={show}
                  setShow={setShow}
                  value={value}
                  error={errors[`${pricing}StartsAt`] && 'This field is required'}
                />
              )
            }}
            rules={{ required: true }}
          />

          <Controller
            name={`${pricing}EndsAt`}
            control={control}
            render={({ field: { onChange, value } }) => {
              let show = showEndStandard
              let setShow = setShowEndStandard
              if (pricing === 'earlyBird') {
                show = showEndEarlyBird
                setShow = setShowEndEarlyBird
              } else if (pricing === 'onsite') {
                show = showEndOnsite
                setShow = setShowEndOnsite
              }

              return (
                <DatePicker
                  label="To"
                  disabled={loadingEvent}
                  name={`${pricing}EndsAt`}
                  onChange={(val) => {
                    if (pricing === 'earlyBird') {
                      const formData = getValues()
                      if (dayjs(val).isAfter(formData.standardStartsAt)) {
                        setValue('standardStartsAt', dayjs(val).add(1, 'day'))
                        setValue('standardEndsAt', dayjs(val).add(2, 'day'))
                      }
                    }
                    onChange(val)
                  }}
                  placeholder="End Date"
                  show={show}
                  setShow={setShow}
                  value={value}
                  error={errors[`${pricing}EndsAt`] && 'This field is required'}
                />
              )
            }}
            rules={{ required: true }}
          />
        </div>

        <div className="flex w-full flex-col gap-4 sm:w-1/2">
          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            currency
            icon={<img alt="Pricing" className="ml-1.5 h-5" src={Dollar} />}
            data-testid={`${pricing}FirstPrice`}
            disabled={loadingEvent}
            error={errors[`${pricing}FirstPrice`] && 'This field is required'}
            id={`${pricing}FirstPrice`}
            inputStyles="rounded-none"
            name={`${pricing}FirstPrice`}
            nunito
            label="First License"
            placeholder="Price"
            {...register(`${pricing}FirstPrice`, { required: true })}
          />

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            currency
            icon={<img alt="Pricing" className="ml-1.5 h-5" src={Dollar} />}
            data-testid={`${pricing}AdditionalPrice`}
            disabled={loadingEvent}
            error={errors[`${pricing}AdditionalPrice`] && 'This field is required'}
            id={`${pricing}AdditionalPrice`}
            inputStyles="rounded-none"
            name={`${pricing}AdditionalPrice`}
            nunito
            label="Additional Licenses"
            placeholder="Price"
            {...register(`${pricing}AdditionalPrice`, { required: true })}
          />

          <TextInput
            className="rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
            currency
            icon={<img alt="Pricing" className="ml-1.5 h-5" src={Dollar} />}
            data-testid={`${pricing}RentalPrice`}
            disabled={loadingEvent}
            error={errors[`${pricing}RentalPrice`] && 'This field is required'}
            id={`${pricing}RentalPrice`}
            inputStyles="rounded-none"
            name={`${pricing}RentalPrice`}
            nunito
            label="Device Rental"
            placeholder="Price"
            {...register(`${pricing}RentalPrice`)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="h-full w-full">
      <StateContainer>
        <div className="relative flex h-full w-full flex-col overflow-y-auto p-3">
          <div className="flex flex-row place-items-center justify-between">
            <div className="flex flex-row space-x-3 sm:hidden">
              <div className="flex flex-row place-items-center space-x-1.5 rounded-full bg-status-green pl-3 pr-4 shadow-sm">
                <img alt="Check" className="h-5" src={Check} />
                <span className="text-xs font-medium text-role-admin lg:text-sm">Active</span>
              </div>

              <Button
                background="bg-white"
                icon={<EditIcon className="h-[14px] fill-gray-600" />}
                label="Edit"
                size="md"
                outlined
              />
            </div>
          </div>

          <div className="mb-10 flex w-full flex-row items-center justify-between">
            <EventHeader event={event} />
          </div>

          <div className="flex flex-col gap-6 md:flex-row">
            <div className="basis-1/3">
              <span className="text-lg font-medium text-gray-900">License Pricing Tool</span>
              <p className="text-sm text-gray-500">
                Set pricing and active date range for licenses
              </p>
              <ul className="my-4">
                {PRICING_TYPES.map((pricingType) => {
                  const startsAt =
                    event[`${pricingType.key}StartsAt`] &&
                    dayjs(event[`${pricingType.key}StartsAt`]).format('MM/DD')
                  const endsAt =
                    event[`${pricingType.key}EndsAt`] &&
                    dayjs(event[`${pricingType.key}EndsAt`]).format('MM/DD')

                  return (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                    <li
                      key={pricingType.key}
                      className={cn(
                        'mb-3 flex cursor-pointer items-center gap-2',
                        pricingType.key === pricing && 'border-b border-purple text-purple',
                      )}
                      onClick={() => setPricing(pricingType.key)}
                    >
                      {pricingType.key === pricing && (
                        <img className="ml-2" alt="arrow" src={Arrow} />
                      )}{' '}
                      <span
                        className={cn(
                          'font-bold',
                          pricingType.key === pricing ? 'text-purple' : 'text-gray-550',
                        )}
                      >
                        {pricingType.label}
                      </span>
                      <span className="ml-2 text-sm">
                        {startsAt && `${startsAt} - `}
                        {endsAt}
                      </span>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="relative basis-2/3 rounded-md bg-white px-6 pt-4 shadow-md">
              {renderPricingForm(pricing)}

              <div className="mt-6 flex h-16 items-center justify-end">
                <Button
                  className="z-10 border-purple bg-purple hover:bg-purple-600"
                  disabled={loadingEvent}
                  label="Save"
                  onClick={() => {
                    if (pricing === 'earlyBird') {
                      earlyBirdHandleSubmit(submitEvent)()
                    } else if (pricing === 'standard') {
                      standardHandleSubmit(submitEvent)()
                    } else if (pricing === 'onsite') {
                      onsiteHandleSubmit(submitEvent)()
                    }
                  }}
                />
              </div>
              <div
                className="absolute bottom-0 left-0 z-0 h-16 w-full bg-no-repeat"
                style={{
                  backgroundImage: `url(${Doodlesticks})`,
                  backgroundSize: '115%',
                  backgroundPosition: 'center -30px',
                  filter: 'invert(1)',
                  opacity: 0.07,
                }}
              ></div>
            </div>
          </div>
        </div>
      </StateContainer>
    </div>
  )
})

export default OrganizerEventLicenses
