import React, { useEffect, useState } from 'react'
import { useForm, useWatch, Controller } from 'react-hook-form'
import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'
import Autocomplete from 'react-google-autocomplete'
import PropTypes from 'prop-types'
import _ from 'lodash'

// Images
import User from '../../assets/images/user.svg'
import Location from '../../assets/images/location.svg'

// Components
import { EditIcon } from '../EditIcon'
import { Modal } from '../Modal'
import { Select } from '../Select'
import { TextInput } from '../TextInput'

import { calculatePriceTotal, getPricingData } from './helpers'

const PurchaseLicensesModal = ({
  event,
  cancelButtonLabel,
  licenses,
  loading,
  onSubmit,
  showModal,
  setShowModal,
}) => {
  // State
  const [pricingInfo, setPricingInfo] = useState({
    firstPrice: 0,
    additionalPrice: 0,
    rentalPrice: 0,
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      numberOfLicenses: { label: '1', id: 1 },
      numberOfLeadRetrievalDevices: { label: '0', id: 0 },
      firstName: '',
      lastName: '',
      creditCard: '',
      cardExpiry: '',
      cardCvc: '',
      address: '',
    },
  })

  const pricingFormData = useWatch({ control })

  const { meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } =
    usePaymentInputs()

  useEffect(() => {
    const pricing = getPricingData(event)
    setPricingInfo(pricing)
  }, [])

  const noPricing = pricingInfo.firstPrice === null && pricingInfo.additionalPrice === null

  const configureSubmitLabel = () => {
    if (noPricing) {
      return 'Pricing Not Set'
    }

    return `Purchase ($${calculatePriceTotal(
      pricingFormData,
      pricingInfo,
      licenses.length,
      licenses.filter((license) => !license.isFreeLicense && !license.isRefunded).length,
    )})`
  }

  return (
    <Modal
      actions={[
        {
          type: 'cancel',
          label: cancelButtonLabel,
          onClick: () => setShowModal(false),
        },
        {
          disabled: noPricing,
          type: 'submit',
          label: configureSubmitLabel(),
          onClick: () => handleSubmit(onSubmit)(),
        },
      ]}
      icon={<EditIcon className="h-5 fill-white sm:h-6" />}
      content={
        <div className="mt-3 sm:mt-5">
          <div>
            <div className="text-sm text-gray-600">
              <span className="text-sm text-gray-600">
                In order to start building your Lead Retrieval App and access your leads, you must
                purchase at least one (1) license. Please select from the below and complete your
                purchase.
              </span>
              <div className="mt-5">
                <span className="font-bold">First License</span> -{' '}
                {pricingInfo.firstPrice !== null
                  ? `$${pricingInfo.firstPrice}`
                  : 'Pricing Not Set'}
              </div>
              <div>
                <span className="font-bold">Additional Licenses</span> -{' '}
                {pricingInfo.additionalPrice !== null
                  ? `$${pricingInfo.additionalPrice}`
                  : 'Pricing Not Set'}
              </div>
              <div className="mb-5">
                <span className="font-bold">Lead Retrieval Device Rental (optional)*</span> -{' '}
                {pricingInfo.rentalPrice !== null
                  ? `$${pricingInfo.rentalPrice}/rental`
                  : 'Pricing Not Set'}
              </div>
              <div className="italic">
                *Licenses can be used on your smartphone or by renting a device
              </div>
              <div className="my-5">
                <Controller
                  name="numberOfLicenses"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      data-testid="numberOfLicenses"
                      disabled={loading}
                      error={errors.numberOfLicenses && 'This field is required'}
                      fullWidth
                      id="numberOfLicenses"
                      name="numberOfLicenses"
                      nunito
                      onChange={onChange}
                      options={_.map(_.range(0, 51), (i) => ({ id: i, label: `${i}` }))}
                      label="Number of Licenses"
                      placeholder="Select a number of licenses to purchase"
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="mb-5">
                <Controller
                  name="numberOfLeadRetrievalDevices"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      className="rounded-2xl border-gray-550 py-2.5 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                      data-testid="numberOfLeadRetrievalDevices"
                      disabled={loading}
                      error={errors.numberOfLeadRetrievalDevices && 'This field is required'}
                      fullWidth
                      id="numberOfLeadRetrievalDevices"
                      name="numberOfLeadRetrievalDevices"
                      nunito
                      onChange={onChange}
                      options={_.map(_.range(0, 21), (i) => ({ id: i, label: `${i}` }))}
                      label="Number of Lead Retrieval Device Rental(s) (does not include license)"
                      placeholder="Select a number of lead retrieval devices to rent"
                      style={{ flex: true, width: '100%' }}
                      value={value}
                    />
                  )}
                  rules={{ required: true }}
                />
              </div>
              <div className="mx-auto">
                <div className="font-nunito font-medium text-gray-700">Card Details</div>
                <div className="flex gap-2">
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        className="mr-2 mt-2 rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="firstName"
                        disabled={loading}
                        error={errors.firstName && 'This field is required'}
                        fullWidth
                        icon={<img alt="User" className="ml-2 h-4" src={User} />}
                        id="firstName"
                        onChange={onChange}
                        name="firstName"
                        placeholder="First Name"
                        style={{ flex: true, width: '100%' }}
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        className="mt-2 rounded-2xl border-gray-550 py-2.5 pl-9 pr-4 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="lastName"
                        disabled={loading}
                        error={errors.lastName && 'This field is required'}
                        fullWidth
                        icon={<img alt="User" className="ml-2 h-4" src={User} />}
                        id="lastName"
                        onChange={onChange}
                        name="lastName"
                        placeholder="Last Name"
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="mt-2 flex items-center rounded-2xl border border-gray-550 py-2.5 pl-2 pr-4 focus-within:border-purple">
                  <div className="basis-2">
                    <svg {...getCardImageProps({ images })} />
                  </div>
                  <Controller
                    name="cardNumber"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        {...getCardNumberProps({ onChange })}
                        containerClassName="w-full"
                        className="border-0 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="cardNumber"
                        disabled={loading}
                        id="cardNumber"
                        name="cardNumber"
                        placeholder="Card number"
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <Controller
                    name="cardExpiry"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        {...getExpiryDateProps({ onChange })}
                        containerClassName="basis-26"
                        className="border-0 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="cardExpiry"
                        disabled={loading}
                        id="cardExpiry"
                        name="cardExpiry"
                        placeholder="MM / YY"
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                  <Controller
                    name="cardCvc"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        {...getCVCProps({ onChange })}
                        containerClassName="basis-26"
                        className="border-0 placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                        data-testid="cardCvc"
                        disabled={loading}
                        id="cardCvc"
                        name="cardCvc"
                        placeholder="CVC"
                        value={value}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {meta.isTouched && meta.error && (
                  <div className="mt-1 w-full bg-error-light px-2 py-1">
                    <p className="text-center text-sm font-medium text-error-dark">{meta.error}</p>
                  </div>
                )}

                {(errors.cardCvc || errors.cardExpiry || errors.cardNumber) && (
                  <div className="mt-1 w-full bg-error-light px-2 py-1">
                    <p className="text-center text-sm font-medium text-error-dark">
                      This field is required
                    </p>
                  </div>
                )}
                <div className="mt-5">
                  <div className="font-nunito font-medium text-gray-700">Billing address</div>
                  <div className="relative">
                    <img
                      alt="Location"
                      className="absolute left-1 top-2.5 ml-1.5 h-5"
                      src={Location}
                    />
                    <Controller
                      name="address"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <Autocomplete
                          data-testid="billingAddress"
                          className="w-full rounded-2xl border border-gray-550 py-2.5 pl-8 pr-4 font-medium text-gray-700 outline-none placeholder:font-normal placeholder:text-gray-600 focus-within:border-purple"
                          apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
                          id="address"
                          name="address"
                          onPlaceSelected={(place) => onChange(place)}
                          options={{
                            types: ['address'],
                          }}
                          placeholder="Enter billing address"
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                  {errors.address && (
                    <div className="mt-1 w-full bg-error-light px-2 py-1">
                      <p className="text-center text-sm font-medium text-error-dark">
                        This field is required
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      loading={loading}
      open={showModal}
      setOpen={setShowModal}
      title="Purchase Licenses"
    />
  )
}

PurchaseLicensesModal.defaultProps = {
  cancelButtonLabel: 'Cancel',
  loading: false,
}

PurchaseLicensesModal.propTypes = {
  cancelButtonLabel: PropTypes.string,
  event: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  licenses: PropTypes.array.isRequired,
}

export default PurchaseLicensesModal
